<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-row class="auth-inner m-0">
      <b-col
        cols="12"
        class="d-flex justify-content-center"
      >
        <b-img
          src="@/@core/assets/image/logo-komerce-new-tag.png"
        />
      </b-col>

      <b-card :class="modeLogin === true ? 'text-white mt-2' : 'd-none'">
        <b-card-title class="mb-1 text-center">
          Masuk
        </b-card-title>
        <b-card-text class="mb-2 text-center text-black">
          Silahkan masuk dan memulai kemudahan mengelola e-commerce dalam 1 tempat.
        </b-card-text>
        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <b-form-group
              label="Username atau Email"
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                label="Username atau Email"
                vid="email"
                rules="required"
                :custom-messages="custommessages1"
              >
                <b-form-input
                  id="login-email"
                  v-model="usernameEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="john@mail.com"
                  @keypress="NoSpace($event)"
                />
                <small class="text-primary">{{ errors[0] }}</small>
                <b-col>
                  <b-row
                    v-if="!!error"
                    class="align-items-center justify-content-between mt-50"
                  >
                    <small
                      class="text-primary"
                    >
                      <strong>
                        {{ error }}
                      </strong>
                    </small>
                    <b-button
                      v-if="showResendEmailVerification"
                      class="ml-50 btn-icon"
                      variant="flat-primary"
                      size="sm"
                      @click="resendEmailVerification"
                    >
                      <b-spinner
                        v-if="loadingResendVerification"
                        small
                      />
                      <strong>
                        Kirim Ulang
                      </strong>
                    </b-button>
                  </b-row>
                  <b-row
                    v-if="messageResendEmailVerification !== ''"
                    class="align-items-center mt-50"
                  >
                    <small
                      class="text-primary"
                    >
                      <strong>
                        {{ messageResendEmailVerification }}
                      </strong>
                    </small>
                  </b-row>
                </b-col>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <label for="login-password">Password</label>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required"
                :custom-messages="custommessages"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                    @keypress="NoSpace($event)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div class="d-flex justify-content-left text-left">
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small style="margin-right:10px;">Lupa Password?</small>
                </b-link>
              </div>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group v-if="false">
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="invalid || loading"
            >
              <b-spinner
                v-if="loading"
                small
              />
              Masuk
            </b-button>
          </b-form>
        </validation-observer>

        <!-- </b-col> -->
      </b-card>

      <b-card :class="modeVerificationEmail === true ? 'mt-2 p-1' : 'd-none'">
        <b-card-title class="mb-1 text-center">
          <h3 class="text-black">
            <strong>
              Verifikasi Email
            </strong>
          </h3>
        </b-card-title>
        <b-card-text class="mb-1 text-center text-black">
          Cek email kamu, verifikasi telah dikirimkan.
          Belum menerima? {{ countTimerEmail === 0 ? 'Kirim ulang (60 detik)' : '' }}
        </b-card-text>
        <b-row class="justify-content-center mb-1">
          <small>Mohon tunggu {{ countTimerEmail }} detik untuk mengirim ulang.</small>
        </b-row>

        <b-row class="justify-content-center mb-1">
          <b-button
            :variant="countTimerEmail === 0 ? 'flat-primary' : 'flat-dark'"
            size="sm"
            :disabled="countTimerEmail !== 0"
            class="btn-icon"
            @click="resendEmailVerification"
          >
            Kirim Ulang
          </b-button>
        </b-row>

        <b-button
          variant="primary"
          block
          @click="handleChangeModePage"
        >
          Kembali Masuk
        </b-button>
      </b-card>
    </b-row>
  </div>

</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BCardTitle,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BAlert,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { mapState } from 'vuex'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'
import secureLocalStorage from '@/libs/secureLocalstorage'
import { NoSpace } from '@/libs/helpers'
import { komtimAxiosIns } from '@/libs/axios'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BForm,
    // BAlert,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userId: '',
      error: '',
      status: '',
      showResendEmailVerification: true,
      usernameEmail: '',
      password: '',
      loading: false,
      userAccess: [],
      // validation rules
      required,
      email,
      custommessages: {
        required: 'Mohon masukan password Kamu.',
      },
      custommessages1: {
        required: 'Mohon masukan username atau email.',
      },

      messageResendEmailVerification: '',
      loadingResendVerification: false,

      countTimerEmail: 60,

      // Mode Page
      modeVerificationEmail: false,
      modeLogin: true,

      fcmToken: '',
      NoSpace,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...mapState('menuAdmin', ['listMenu']),
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          this.showResendEmailVerification = false
          this.error = ''
          this.userId = ''

          useJwt
            .login({
              username_email: this.usernameEmail,
              password: this.password,
              login_from: 'website',
            })
            .then(async response => {
              const { data } = response.data
              this.userId = data.id
              if (response.data.status === false) {
                this.error = 'Maaf, username atau password yang Kamu masukan salah'
                this.loading = false
              } else {
                useJwt.setToken(response.data.data)
                this.getUserAccess()
              }
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Gagal',
                  text: 'Gagal untuk login, silahkan coba lagi!',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
              this.loading = false
            })
        }
      })
    },
    async getUser(userData) {
      // this.userId = userData.id
      this.$store.dispatch('menuAdmin/getMenuAdmin')
      // eslint-disable-next-line no-param-reassign
      // if (userData.email_verified_at !== null) userData.email_verified_at = null

      this.$http
        .post('/user/get-profile', {
          user_id: this.userId,
        })
        .then(async response => {
          let ability = []
          let { data } = response.data
          data = Array.isArray(data) ? data[0] : data
          const role = data.role_name.toUpperCase()
          const topupMenu = this.listMenu.map(item => item.name).includes('Topup')
          if (['ADMIN'].includes(role)) {
            ability = [
              {
                action: 'read',
                subject: 'Dashboard',
              },
              {
                action: 'manage',
                subject: 'Kelola User',
              },
              {
                action: 'manage',
                subject: 'Campaign',
              },
              {
                action: 'manage',
                subject: 'Pencairan',
              },
              {
                action: 'manage',
                subject: 'Komtim',
              },
              {
                action: 'manage',
                subject: 'Komship',
              },
              {
                action: 'manage',
                subject: 'Partner',
              },
              {
                action: 'manage',
                subject: 'Management',
              },
              {
                action: 'manage',
                subject: 'PUMKM',
              },
              {
                action: 'manage',
                subject: 'Affiliate',
              },
              {
                action: 'manage',
                subject: 'Komform',
              },
              {
                action: 'manage',
                subject: 'Komchat',
              },
              {
                action: 'manage',
                subject: 'Komads',
              },
              {
                action: 'manage',
                subject: 'Komcards',
              },
              {
                action: 'manage',
                subject: 'Komclass',
              },
              {
                action: 'manage',
                subject: 'Kompack',
              },
              {
                action: 'manage',
                subject: 'Komchat',
              },
              {
                action: 'manage',
                subject: 'Komtim - Dashboard',
              },
              {
                action: 'manage',
                subject: 'Komtim - Talent',
              },
              {
                action: 'manage',
                subject: 'Komtim - Training',
              },
              {
                action: 'manage',
                subject: 'Komtim - Partner',
              },
              {
                action: 'manage',
                subject: 'Komtim - Device',
              },
              {
                action: 'manage',
                subject: 'Komtim - Assignment',
              },
              {
                action: 'manage',
                subject: 'Komtim - Evaluasi Partner',
              },
              {
                action: 'manage',
                subject: 'Komtim - Churn Partner',
              },
              {
                action: 'manage',
                subject: 'Komtim - Keuangan',
              },
              {
                action: 'manage',
                subject: 'Komtim - Komtim (Old)',
              },
            ]
          } else {
            ability = [{ action: 'read', subject: 'Dashboard' }, ...this.userAccess]
          }

          data.ability = ability
          this.$ability.update(ability)

          localStorage.setItem('userData', JSON.stringify(data))
          this.$store.commit('auth/UPDATE_USER_DATA', data)

          this.$router
            .go(getHomeRouteForLoggedInUser(role))
            .then(() => {})
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Failed',
                  text: error.response.data.error,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            })
        })
        .finally(() => {
          this.loading = false
        })
    },
    resendEmailVerification() {
      this.loadingResendVerification = true
      this.showResendEmailVerification = true
      if (this.countTimerEmail !== 60) {
        this.countTimerEmail = 60
      }

      this.$http
        .get(`/resend_verification_email/${this.userId}`)
        .then(() => {
          this.error = ''
          this.loadingResendVerification = false
          this.modeLogin = false
          this.modeVerificationEmail = true
          this.countDownTimer()
        })
        .catch(() => {
          this.loadingResendVerification = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              text: 'Gagal untuk login, silahkan coba lagi!',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getPartnerProfile(userId) {
      return this.$http
        .get(`/user/partner/get-profile/${userId}`)
        .then(response => response.data.data[0])
        .finally(() => {
          this.loading = false
        })
    },
    getAccessKomship(id) {
      const params = {
        user_id: id,
      }
      return this.$http.get('/user/partner/get-menu-komship-member', {
        params,
      }).then(response => response.data.data)
    },
    logout() {
      useJwt
        .logout({})
        .then(() => {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          secureLocalStorage.clearItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleChangeModePage() {
      this.modeVerificationEmail = false
      this.modeLogin = true
    },
    countDownTimer() {
      if (this.countTimerEmail > 0) {
        setTimeout(() => {
          this.countTimerEmail -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    getUserAccess() {
      const params = `username_email=${this.usernameEmail}`
      const url = `v1/user_access/detail?${params}`
      komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          if (data) {
            this.userAccess = data.menu_access.map(e => {
              if (e.parent_id) return ({ action: 'manage', subject: `${e.parent_name} - ${e.name}` })
              return ({ action: 'manage', subject: e.name })
            })
          }
        })
        .catch(err => {
          // this.$toast_error({ message: 'Failed to get user access' })
        })
        .finally(() => {
          this.getUser()
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
